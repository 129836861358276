export const defaultHeadPhone = '+1';

export const numberPrefixOptions = [
  { value: '+1', label: '+1' },
  // { value: '+2', label: '+2' },
  // { value: '+84', label: '+84' },
];

export const removePhonePrefix = (phone: any) => {
  for (const prefix of numberPrefixOptions) {
    if (phone?.startsWith(prefix?.value)) {
      return phone?.slice(prefix?.value?.length); // Loại bỏ tiền tố
    }
  }
  return phone; // Trả về nguyên nếu không khớp
};

export const splitPhoneNumber = (phoneNumber: any) => {
  const prefix = numberPrefixOptions.find(option => phoneNumber.startsWith(option.value));

  if (prefix) {
    const postfix = phoneNumber.slice(prefix.value.length);
    return { prefix: prefix.value, postfix };
  }

  return { prefix: null, postfix: phoneNumber };
};
